@import url(../../node_modules/@syncfusion/ej2-material-theme/styles/customized/material.css);
#root {
  height: 100%;
  width: 100%;
  background-color: #132433; }

a.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
  background-color: #1890ff; }

a.e-link.e-numericitem.e-spacing.e-currentitem.e-active {
  border-radius: 4px; }

.ant-notification,
.ant-message {
  z-index: 1040; }

/******  Form Styling ******/
.ant-form-item-label {
  line-height: 18px; }

.ant-form-item-label label {
  color: #212121;
  font-size: 13px !important; }

.ant-form-explain {
  font-size: 11px; }

.ant-form-item {
  margin-bottom: 4px; }

.ant-radio-group-solid {
  display: flex;
  padding: 2px 0; }
  .ant-radio-group-solid .ant-radio-button-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center; }

/************************/
.ant-table-tbody .actions {
  text-align: center; }

.ant-collapse-content {
  border: solid 1px #cde7ff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .ant-collapse-content .ant-collapse-content-box {
    padding: 16px !important; }

.hide-expand-icon .ant-table-row-expand-icon-cell span {
  visibility: hidden; }

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5; }

.tagDashboard {
  background: #f0f2f5; }

.ellipsis {
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden; }
